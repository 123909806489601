@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap");
/* 프로젝트의 글로벌 CSS 파일에 추가 */
@import "swiper/css";
@import "swiper/css/effect-cards";
@import "swiper/css/pagination";
@import "swiper/css/navigation";

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-weight: bold;
  margin-bottom: 40px;
}
.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: rgba(0, 0, 0, 0.2);
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: #000000;
}

.swiper-container {
  padding-bottom: 40px;
}

body {
  font-family: "Noto Sans KR", sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
}
::selection {
  color: white;
  background-color: #386937;
}
